import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, logOut } from '../helpers/authentication';
import styles from './LoginPage.module.css';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  let loading = false;
  const signInUser = async () => {
    loading = true;
    signIn(email, password).then((user) => {
      if (user) {
        return navigate('/chatbot');
      }
    }).catch(() => {
      setError(true);
      loading = false;
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <label htmlFor="userEmail">
          Email
        </label>
        <input
          id="userEmail"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <label htmlFor="userPassword">
          Password
        </label>
        <input
          id="userPassword"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <p className={styles.error}>Error logging in, please check your details</p>}
        <button type="button" onClick={signInUser} disabled={loading}>Sign in</button>
        <button type="button" onClick={logOut} disabled={loading}>Sign out</button>
      </div>
    </div>
  );
}

export default LoginScreen;
