import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../helpers/authentication';

function PrivateRoute({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // UseEffect to remove event listener
  useEffect(() => auth.onAuthStateChanged((user) => {
    setLoading(false);
    if (user) {
      return setLoggedIn(true);
    }
    setLoggedIn(false);
  }), []);

  if (loading) {
    return <div>loading</div>;
  }

  if (loggedIn) {
    // authorized so return child components
    return children;
  }
  return <Navigate to="/" from={location} />;
}
export default PrivateRoute;
