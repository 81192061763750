import React, { useEffect } from 'react';

function Player() {
  let load = false;

  useEffect(() => {
    if (load) return;
    load = true;
    window.load();
  }, []);

  return (
    <div id="player" />
  );
}

export default Player;
