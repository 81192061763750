/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ChatbotScreen from '../pages/ChatbotPage';
import LoginScreen from '../pages/LoginPage';

function CustomRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route
        path="/chatbot"
        element={(
          <PrivateRoute>
            <ChatbotScreen />
          </PrivateRoute>
          )}
      />
    </Routes>
  );
}

export default CustomRoutes;
