/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Player from '../components/Player';
import Chatbot from '../components/Chatbot';
import Overlay from '../components/Overlay';
import '../App.css';
import '../player.css';
import OpusMediaRecorderView from '../components/OpusMediaRecorder';

const BOT_MESSAGE_WELCOME = 'Hi there! I\'m Alice. I\'ll do my best to answer any queries you may have.';
function App() {
  const [conversation, setConversation] = useState([{ user: 'bot', message: BOT_MESSAGE_WELCOME, id: 0 }]);

  const [sessionId, setSessionId] = useState();

  useEffect(() => {
    setSessionId(Math.random().toString(36).substring(7));
  }, []);
  return (
    <div id="playerUI">
      <Chatbot
        conversation={conversation}
        setConversation={setConversation}
        sessionId={sessionId}
        setSessionId={setSessionId}
      />
      <Player />
      <Overlay />
      <OpusMediaRecorderView
        setConversation={setConversation}
        sessionId={sessionId}
      />
    </div>
  );
}

export default App;
