/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';

import MediaRecorder from 'opus-media-recorder';

const ID_DIV_TRANSLUCENT_OVERLAY_ICON_SPEECH = 'div-translucent-overlay-icon-speech';

const workerOptions = {
  encoderWorkerFactory() {
    return new Worker(`${process.env.PUBLIC_URL}/opus-media-recorder/encoderWorker.umd.js`);
  },
  OggOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/OggOpusEncoder.wasm`,
  WebMOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/WebMOpusEncoder.wasm`,
};

let recorder;

function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function OpusMediaRecorderView({ setConversation }) {
  const [state, setState] = useState('notInitialized');
  const [isRecording, setIsRecording] = useState(false);
  // const [data, setData] = useState([]);
  // const [blob, setBlob] = useState();

  const onDataAvailable = async (e) => {
    const base64 = await blobToBase64(e.data);
    console.log(base64);
    // const blob = new Blob(e.data, { type: 'audio/wave' });

    const body = {
      inputAudio: base64,
      sessionId: '123123',
    };

    const { response: data, transcript } = await fetch('https://wss.digitalhuman.expert/api/agent/speech-input', {

      mode: 'cors',
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .catch((err) => console.log(err));

    if (transcript && transcript !== '') {
      setConversation((prev) => [
        ...prev,
        { user: 'customer', message: transcript, id: prev.length },
      ]);
    }

    let botResponse = '';
    data.forEach(async (message) => {
      if (message.text) {
        setConversation((prev) => [
          ...prev,
          { user: 'bot', message: message.text.text[0], id: prev.length },
        ]);
        botResponse += message.text.text[0];
      }
    });

    if (botResponse.length !== 0) {
      await fetch(
        `https://wss.digitalhuman.expert/api/agent/get-speech?${

          new URLSearchParams({
            text: encodeURIComponent(botResponse),
          })}`,
        {
          method: 'get',
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(({ response }) => {
          console.log(response);
        }).catch((err) => console.log(err));
    }
  };

  const start = () => {
    console.log('start recording called');
    // navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
    //   const options = { mimeType: 'audio/wave' };
    //   recorder = new MediaRecorder(stream, options, workerOptions);
    //   setState('inactive');
    //   recorder.start();

    //   recorder.addEventListener('dataavailable', (e) => {
    //     console.log('Recording stopped, data available');
    //     onDataAvailable(e);
    //   });
    //   recorder.addEventListener('start', (_e) => {
    //     console.log('start');
    //     // setData([]);
    //     setState('recording');
    //     setIsRecording(true);
    //   });
    //   recorder.addEventListener('stop', async (_e) => {
    //     console.log('stop');

    //     setIsRecording(false);
    //     stream.getTracks().forEach((track) => {
    //       track.stop();
    //     });
    //     setState('inactive');
    //   });
    //   recorder.addEventListener('error', (_e) => {
    //     console.log('error', _e);
    //     recorder.stop();
    //   });
    // });
  };

  const stop = () => {
    console.log('stop recording called');
    // recorder.stop();
  };

  return (
    <button type="button" onTouchStart={start} onTouchEnd={stop}>
      <img alt="Tell me your question." id={ID_DIV_TRANSLUCENT_OVERLAY_ICON_SPEECH} src="images/speech.svg" />
    </button>

  );
}

export default OpusMediaRecorderView;
