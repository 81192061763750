export const sendTextInput = async (sessionId, text) => {
  try {
    const body = {
      message: text,
      sessionId,
    };
    const response = await fetch('https://wss.digitalhuman.expert/api/agent/text-input', {
      mode: 'cors',
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getBotSpeech = async (botResponseText) => fetch(
  `https://wss.digitalhuman.expert/api/agent/get-speech?${
    new URLSearchParams({
      text: encodeURIComponent(botResponseText),
    })}`,
  {
    method: 'get',
  },
)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
  }).catch((e) => console.log(e));

export const sendSpeechInput = async (sessionId, base64Audio) => {
  try {
    const body = {
      inputAudio: base64Audio,
      sessionId,
    };
    const res = await fetch('https://wss.digitalhuman.expert/api/agent/speech-input', {
      mode: 'cors',
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.ok) {
      const data = await res.json();
      console.log('aaaaaaaaa', data);
      return { data: data.response, transcript: data.transcript };
    }
  } catch (e) {
    console.log(e);

    return {};
  }
};
