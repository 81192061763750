/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/*
 *         Filename: Chatbot.jsx
 *    Last Modified: 2022-11-13
 *          Version: 1.0.0
 */

// -----------------------------------------------------------------------------
// Imports
// -----------------------------------------------------------------------------
import React, { useEffect } from 'react';
import MediaRecorder from 'opus-media-recorder';
import blobToBase64 from '../helpers/helpers';
import { sendSpeechInput, getBotSpeech, sendTextInput } from '../helpers/queries';

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------
const BOT_NAME = 'bot';
const BOT_NAME_FRIENDLY = 'Alice';
const BOT_MESSAGE_AWAIT = 'Please wait a moment...';
const BOT_MESSAGE_WELCOME = `Hi there! I'm ${BOT_NAME_FRIENDLY}. I'll do my best to answer any queries you may have.`;
const BOT_RICH_CONTENT_TYPES = {
  chips: {
    stringValue: 'chips',
  },
};
const CLASS_NAME_GLOBAL = 'chatbot';
const CUSTOMER_NAME = 'customer';
const ID_DIV_TRANSLUCENT_OVERLAY = 'div-translucent-overlay';
const ID_DIV_TRANSLUCENT_OVERLAY_ICON_SPEECH = `${ID_DIV_TRANSLUCENT_OVERLAY}-icon-speech`;
const ID_SECTION_BOT_MESSAGE = 'section-botmessage';
const ID_SECTION_INPUT_PANE = 'section-inputpane';
const ID_SECTION_INPUT_PANE_BUTTON_CHIPS = `${ID_SECTION_INPUT_PANE}-button-chips`;
const ID_SECTION_INPUT_PANE_BUTTON_LEFT = `${ID_SECTION_INPUT_PANE}-button-left`;
const ID_SECTION_INPUT_PANE_BUTTON_RIGHT = `${ID_SECTION_INPUT_PANE}-button-right`;
const ID_SECTION_INPUT_PANE_CHIPS = `${ID_SECTION_INPUT_PANE}-chips`;
const ID_SECTION_INPUT_PANE_TEXT_FIELD = `${ID_SECTION_INPUT_PANE}-textfield`;
const PLACEHOLDER_SECTION_INPUT_PANE_TEXT_FIELD = `Ask ${BOT_NAME_FRIENDLY} a question...`;
const STATES_SECTION_INPUT_PANE_BUTTON = {
  cancel: {
    img: {
      alt: 'Cancel and enter your message.',
      src: 'images/cancel.svg',
    },
  },
  clear: {
    img: {
      alt: 'Clear your composed message.',
      src: 'images/clear.svg',
    },
  },
  restart: {
    img: {
      alt: 'Restart the conversation.',
      src: 'images/restart.svg',
    },
  },
  send: {
    img: {
      alt: 'Send your message.',
      src: 'images/send.svg',
    },
  },
  speech: {
    img: {
      alt: 'Tap to tell me your question.',
      src: 'images/speech.svg',
    },
  },
  stop: {
    img: {
      alt: 'Stop and send your message.',
      src: 'images/stop.svg',
    },
  },
};

// -----------------------------------------------------------------------------
// Global Properties
// -----------------------------------------------------------------------------
let botMessage;
let inputPaneButtonChips;
let inputPaneButtonLeft;
let inputPaneButtonRight;
let inputPaneChips;
let inputPaneTextField;
let translucentOverlay;
let translucentOverlayIconSpeech;

let recorder;
let lastBotMessage = '';
// -----------------------------------------------------------------------------
// Functions
// -----------------------------------------------------------------------------
const workerOptions = {
  encoderWorkerFactory() {
    return new Worker(`${process.env.PUBLIC_URL}/opus-media-recorder/encoderWorker.umd.js`);
  },
  OggOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/OggOpusEncoder.wasm`,
  WebMOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/WebMOpusEncoder.wasm`,
};

function Chatbot({
  conversation, setConversation, sessionId, setSessionId,
}) {
  useEffect(() => window.addResponseEventListener('message-box', (descriptor) => {
    const responseMessage = JSON.parse(descriptor);
    if (responseMessage.text !== undefined) {
      showMessageFromBot(lastBotMessage);
    }
    // addMessage(responseMessage.text, false);
  }), []);
  // const [isRecording, setIsRecording] = useState(false);

  // Bot Response: Messages ****************************************************
  /* Sends the message from the customer to the bot. This was captured by
   * speech, or from the input pane by tapping a chip or entering a single-line
   * message into the text field. */
  const sendCustomerMessageToBot = async (message) => {
    let customerMessage = message;
    if (customerMessage === undefined) {
      customerMessage = inputPaneTextField.value;
    }
    setConversation((state) => [
      ...state,
      { user: CUSTOMER_NAME, message: customerMessage, id: state.length },
    ]);
    showMessageFromBot(BOT_MESSAGE_AWAIT);
    inputPaneTextField.blur();

    const data = await sendTextInput(sessionId, customerMessage);
    /* Supports responses from the bot that are messages and rich content; a
     * response can contain both a message and rich content. */
    const botResponse = {
      message: '',
      richContent: {},
    };
    data?.forEach(async (response) => {
      // Handles a message response from the bot.
      if (response.text) {
        setConversation((state) => [
          ...state,
          { user: BOT_NAME, message: response.text.text[0], id: state.length },
        ]);
        botResponse.message += response.text.text[0];
      }

      // Handles a rich content response from the bot.
      const richContent = getRichContentFromBot(response);
      if (richContent !== undefined) {
        /* Wait for dismissal of the on-screen keyboard before showing the
         * series of chips within the input pane. */
        setTimeout(() => {
          // Handles a rich content containing a series of chips.
          showInputPaneChipsForRichContent(richContent);
          shrinkInputPaneTextField();
        }, 320);
      }
    });
    if (botResponse.message.length !== 0) {
      // showMessageFromBot(botResponse.message);
      lastBotMessage = botResponse.message;
      await getBotSpeech(botResponse.message);
    }
  };

  const onDataAvailable = async (e) => {
    const base64 = await blobToBase64(e.data);
    console.log(base64);

    const { data, transcript } = await sendSpeechInput(sessionId, base64);

    if (transcript && transcript !== '') {
      setConversation((prev) => [
        ...prev,
        { user: 'customer', message: transcript, id: prev.length },
      ]);
    }

    /* Supports responses from the bot that are messages and rich content; a
     * response can contain both a message and rich content. */
    const botResponse = {
      message: '',
      richContent: {},
    };
    data?.forEach(async (response) => {
      // Handles a message response from the bot.
      if (response.text) {
        setConversation((state) => [
          ...state,
          { user: BOT_NAME, message: response.text.text[0], id: state.length },
        ]);
        botResponse.message += response.text.text[0];
      }

      // Handles a rich content response from the bot.
      const richContent = getRichContentFromBot(response);
      if (richContent !== undefined) {
        /* Wait for dismissal of the on-screen keyboard before showing the
         * series of chips within the input pane. */
        setTimeout(() => {
          // Handles a rich content containing a series of chips.
          showInputPaneChipsForRichContent(richContent);
          shrinkInputPaneTextField();
        }, 320);
      }
    });
    if (botResponse.message.length !== 0) {
      lastBotMessage = botResponse.message;
      await getBotSpeech(botResponse.message);
    }
  };

  const startRecording = () => {
    console.log('start recording called');
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const options = { mimeType: 'audio/wave' };
      recorder = new MediaRecorder(stream, options, workerOptions);
      recorder.start();

      recorder.addEventListener('dataavailable', (e) => {
        console.log('Recording stopped, data available');
        onDataAvailable(e);
      });
      recorder.addEventListener('start', () => {
        console.log('start');
        // setData([]);
        // setIsRecording(true);
      });
      recorder.addEventListener('stop', async () => {
        console.log('stop');

        // setIsRecording(false);
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      });
      recorder.addEventListener('error', (_e) => {
        console.log('error', _e);
        recorder.stop();
      });
    });
  };

  const stopRecording = () => {
    console.log('stop recording called');
    recorder.stop();
  };

  /* Hides the last message received from the bot that is currently displayed to
   * the customer; this is to reinforce the bot is capturing the customers
   * question. */
  const hideMessageFromBot = () => {
    botMessage.style.opacity = '0.00';
  };

  /* Shows and updates the last message received from the bot to the customer,
   * which may have previously been hidden. */
  const showMessageFromBot = (message) => {
    botMessage.innerHTML = message;
    botMessage.removeAttribute('style');
  };

  // Bot Response: Rich Content ************************************************
  // Retrieves any rich content received from the bot.
  const getRichContentFromBot = (response) => {
    let richContent;
    try {
      richContent = response.payload.fields.richContent;
    } catch (error) {
      richContent = undefined;
    }
    return richContent;
  };

  /* Extracts a series of chips from the given rich content received from the
   * bot. */
  const extractChipsFromRichContent = (richContent) => {
    let chips = richContent;
    if (chips !== undefined) {
      if (Array.isArray(chips)) {
        [chips] = chips;
        chips = extractChipsFromRichContent(chips);
      } else {
        try {
          [chips] = Object.values(chips);
          if (chips.type.stringValue === BOT_RICH_CONTENT_TYPES.chips.stringValue) {
            chips = Object.values(chips.options.listValue.values);
            Object.keys(chips).forEach((key) => {
              chips[key] = Object.values(chips[key].structValue).at(0).text;
            });
          }
        } catch (error) {
          chips = extractChipsFromRichContent(chips);
        }
      }
    }
    return chips;
  };

  /* Generates a chip to be shown within the input pane; the customer can tap
   * the chip to send a predefined message to the bot. */
  const generateChipWithMessage = (message) => {
    const chip = document.createElement('button');
    chip.innerHTML = message;
    chip.addEventListener('click', () => {
      touchEndEventOnInputPaneChip(chip);
    }, { once: true });
    return chip;
  };

  /* Shows a series of chips within the input pane corresponding to the rich
   * content received from the bot. */
  const showInputPaneChipsForRichContent = (richContent) => {
    const chips = extractChipsFromRichContent(richContent);
    Object.values(chips).forEach((chipValue) => {
      const message = chipValue.stringValue;
      const chip = generateChipWithMessage(message);
      inputPaneChips.appendChild(chip);
    });
    inputPaneTextField.addEventListener('transitionend', () => {
      inputPaneChips.style.pointerEvents = 'auto';
    }, { once: true });
    updateInputPaneButtonState(inputPaneButtonChips, STATES_SECTION_INPUT_PANE_BUTTON.cancel);
    inputPaneButtonChips.style.display = 'block';
  };

  // Removes any series of chips currently shown within the input pane.
  const clearInputPaneChips = () => {
    inputPaneChips.innerHTML = '';
    inputPaneChips.removeAttribute('style');
    inputPaneButtonChips.removeAttribute('style');
  };

  // Translucent Overlay *******************************************************
  /* Hides the translucent overlay currently displayed over the digital human
   * video feed, including the speech icon. */
  const hideTranslucentOverlay = () => {
    translucentOverlay.removeAttribute('style');
    translucentOverlayIconSpeech.removeAttribute('style');
  };

  /* Overlays a translucent background over the digital human video feed to
   * reinforce attention to the input pane. */
  const showTranslucentOverlay = () => {
    translucentOverlay.style.opacity = '1.00';
    translucentOverlay.style.pointerEvents = 'auto';
  };

  /* Overlays a translucent background over the digital human video feed with a
   * speech icon to reinforce that the bot is capturing the customers
   * question. */
  const showTranslucentOverlayWithSpeechIcon = () => {
    showTranslucentOverlay();
    translucentOverlayIconSpeech.style.opacity = '1.00';
  };

  // Input Pane: Buttons *******************************************************
  // Hides either the left or right button within the input pane.
  const hideInputPaneButton = (button) => {
    const inputPaneButton = button;
    inputPaneButton.style.opacity = '0.00';
    inputPaneButton.style.pointerEvents = 'none';
  };

  /* Shows either the left or right button within the input pane, which would
   * have been previously hidden. */
  const showInputPaneButton = (button) => {
    const inputPaneButton = button;
    inputPaneButton.removeAttribute('style');
  };

  /* Updates the state of the left or right button within the input pane to
   * reflect and correspond to the current flow of the customer's conversation
   * with the bot. */
  const updateInputPaneButtonState = (button, state) => {
    const inputPaneButton = button;
    const inputPaneButtonImg = inputPaneButton.getElementsByTagName('img')[0];
    inputPaneButton.state = state;
    inputPaneButtonImg.alt = state.img.alt;
    inputPaneButtonImg.src = state.img.src;
  };

  // Input Pane: Text Field ****************************************************
  /* Removes any single-line message that has been entered by a customer from
   * the text field within the input pane. */
  const clearInputPaneTextField = () => {
    inputPaneTextField.value = '';
  };

  // Hides the text field within the input pane.
  const hideInputPaneTextField = () => {
    inputPaneTextField.style.opacity = '0.00';
    inputPaneTextField.style.pointerEvents = 'none';
  };

  /* Returns the text field within the input pane to its default state, removing
   * any single-line message that has been entered by a customer. */
  const resetInputPaneTextField = () => {
    inputPaneTextField.removeAttribute('style');
    clearInputPaneTextField();
  };

  /* Shrinks the input pane text field within the input pane to allow the input
   * pane to show rich content, such as a series of chips. */
  const shrinkInputPaneTextField = () => {
    inputPaneTextField.style.marginRight = '0px';
    inputPaneTextField.style.padding = '0px';
    inputPaneTextField.style.maxWidth = '0px';
    inputPaneTextField.style.minWidth = '0%';
    inputPaneTextField.style.flexGrow = '0';
    inputPaneTextField.style.opacity = '0.00';
  };

  /* Shows the text field within the input pane, which would have previously
   * been hidden. */
  const showInputPaneTextField = () => {
    inputPaneTextField.removeAttribute('style');
  };

  // Speech Capture ************************************************************
  // The bot has started capturing a question from the customer.
  const startSpeechCaptureEvent = () => {
    resetInputPaneTextField();
    clearInputPaneChips();
    showTranslucentOverlayWithSpeechIcon();
    hideMessageFromBot();
    hideInputPaneButton(inputPaneButtonLeft);
    hideInputPaneTextField();
    updateInputPaneButtonState(inputPaneButtonRight, STATES_SECTION_INPUT_PANE_BUTTON.stop);
    startRecording();
  };

  /* The customer has finished their question and no longer needs to be
   * captured; their question is sent to the bot. */
  const endSpeechCaptureEvent = () => {
    hideTranslucentOverlay();
    stopRecording();
    showInputPaneButton(inputPaneButtonLeft);
    showInputPaneTextField();
    updateInputPaneButtonState(inputPaneButtonRight, STATES_SECTION_INPUT_PANE_BUTTON.speech);
  };

  // Conversation **************************************************************
  // Starts a new conversation between the bot and the customer.
  const startNewConversationWithBot = () => {
    clearConversation();
    resetInputPaneTextField();
    clearInputPaneChips();
    setSessionId(Math.random().toString(36).substring(7));
    showMessageFromBot(BOT_MESSAGE_WELCOME);
    updateInputPaneButtonState(inputPaneButtonLeft, STATES_SECTION_INPUT_PANE_BUTTON.restart);
    updateInputPaneButtonState(inputPaneButtonRight, STATES_SECTION_INPUT_PANE_BUTTON.speech);
  };

  // Clears any previous conversation undertook between the bot and customer.
  const clearConversation = () => {
    if (conversation.length > 1) {
      conversation.splice(1, conversation.length - 1);
    }
  };

  // Event Listeners ***********************************************************
  /* The customer has tapped a button within the input pane and expects a
   * response from the bot; the response corresponds to the state of the
   * button. */
  const touchEndEventOnInputPaneButton = (event) => {
    const inputPaneButton = event.currentTarget;
    if (inputPaneButton.state !== undefined) {
      switch (inputPaneButton.state) {
        case STATES_SECTION_INPUT_PANE_BUTTON.cancel:
          /* A series of chips is currently shown within the input pane and the
           * customer has not found any chip suitable, therefore they
           * are removed from the input pane. */
          resetInputPaneTextField();
          clearInputPaneChips();
          break;
        case STATES_SECTION_INPUT_PANE_BUTTON.clear:
          /* Prevents dismissal of the on-screen keyboard as their is a high
           * likelihood the customer will enter a new single-line message into
           * the text field within the input pane. */
          event.preventDefault();
          break;
        case STATES_SECTION_INPUT_PANE_BUTTON.restart:
          // Restarts the conversation between the bot and customer.
          startNewConversationWithBot();
          break;
        case STATES_SECTION_INPUT_PANE_BUTTON.send:
          /* Sends the single-line message input by the customer into the text
           * field within the input pane to the bot. */
          sendCustomerMessageToBot();
          break;
        case STATES_SECTION_INPUT_PANE_BUTTON.speech:
          // Starts capturing a question from the customer.
          startSpeechCaptureEvent();
          break;
        case STATES_SECTION_INPUT_PANE_BUTTON.stop:
          // Sends the captured message from the customer to the bot.
          endSpeechCaptureEvent();
          break;
        default:
          /* Ensures the translucent overlay that can be displayed over the
           * digital human video feed is hidden. */
          hideTranslucentOverlay();
          break;
      }
    }

    /* Removes any single-line message that has been entered by a customer from
     * the text field within the input pane. */
    clearInputPaneTextField();
  };

  /* The customer has tapped a chip within the input pane and expects a response
   * from the bot; the response corresponds to predefined message of the
   * chip. */
  const touchEndEventOnInputPaneChip = (event) => {
    const chip = event;
    chip.style.opacity = '0.00';
    chip.style.transform = 'translateY(calc(var(--button-size) * -1.00)';
    chip.addEventListener('transitionend', () => {
      const message = chip.innerHTML;
      resetInputPaneTextField();
      clearInputPaneChips();
      sendCustomerMessageToBot(message);
    }, { once: true });
  };

  /* The customer has tapped return on the on-screen keyboard and expects their
   * single-line message entered into the text field within the input pane to be
   * sent to the bot. */
  const keyboardReturnEventOnInputPaneTextField = (event) => {
    event.preventDefault();
    const keyPressed = event.key;
    if (keyPressed !== undefined && keyPressed === 'Enter') {
      /* Sends the single-line message entered by the customer within the text
       * field within the input pane to the bot, after it has been sent, it is
       * then removed. */
      sendCustomerMessageToBot();
      clearInputPaneTextField();
      inputPaneTextField.blur();
    }
  };

  /* The customer has tapped the text field within the input pane and is
   * expected to enter a single-line message. */
  const inputPaneTextFieldHasFocus = () => {
    // Allows the customer to remove any single-line message entered.
    updateInputPaneButtonState(inputPaneButtonLeft, STATES_SECTION_INPUT_PANE_BUTTON.clear);
    // Sends the single-line message from the customer to the bot.
    updateInputPaneButtonState(inputPaneButtonRight, STATES_SECTION_INPUT_PANE_BUTTON.send);
    /* Reinforce customer focus on the input pane by overlaying a translucent
     * background over the digital human video feed. */
    showTranslucentOverlay();
  };

  // The customer has tapped outside of the text field within the input pane.
  const inputPaneTextFieldLostFocus = () => {
    // Allows the customer to restart their conversation with the bot.
    updateInputPaneButtonState(inputPaneButtonLeft, STATES_SECTION_INPUT_PANE_BUTTON.restart);
    // Allows the customer to send a voice message to the bot.
    updateInputPaneButtonState(inputPaneButtonRight, STATES_SECTION_INPUT_PANE_BUTTON.speech);
    /* Removes the translucent overlay currently displayed over the digital
     * human video feed. */
    hideTranslucentOverlay();
  };

  // Rendering *****************************************************************
  useEffect(() => {
    botMessage = document.getElementById(ID_SECTION_BOT_MESSAGE);
    inputPaneButtonChips = document.getElementById(ID_SECTION_INPUT_PANE_BUTTON_CHIPS);
    inputPaneButtonLeft = document.getElementById(ID_SECTION_INPUT_PANE_BUTTON_LEFT);
    inputPaneButtonRight = document.getElementById(ID_SECTION_INPUT_PANE_BUTTON_RIGHT);
    inputPaneChips = document.getElementById(ID_SECTION_INPUT_PANE_CHIPS);
    inputPaneTextField = document.getElementById(ID_SECTION_INPUT_PANE_TEXT_FIELD);
    translucentOverlay = document.getElementById(ID_DIV_TRANSLUCENT_OVERLAY);
    translucentOverlayIconSpeech = document.getElementById(ID_DIV_TRANSLUCENT_OVERLAY_ICON_SPEECH);
    startNewConversationWithBot();
  }, []);

  return (
    <main className={`${CLASS_NAME_GLOBAL} main`}>
      <div id={ID_DIV_TRANSLUCENT_OVERLAY}>
        <img alt="Tell me your question." id={ID_DIV_TRANSLUCENT_OVERLAY_ICON_SPEECH} src="images/speech.svg" />
      </div>
      <section className={`${CLASS_NAME_GLOBAL} section-botmessage`} id={ID_SECTION_BOT_MESSAGE} />
      <section className={`${CLASS_NAME_GLOBAL} section-inputpane`}>
        <button style={{ height: '86px', width: '86px' }} id={ID_SECTION_INPUT_PANE_BUTTON_LEFT} onTouchEnd={touchEndEventOnInputPaneButton} type="button">
          <img alt="" />
        </button>
        <input id={ID_SECTION_INPUT_PANE_TEXT_FIELD} onBlur={inputPaneTextFieldLostFocus} onFocus={inputPaneTextFieldHasFocus} onKeyUp={keyboardReturnEventOnInputPaneTextField} placeholder={PLACEHOLDER_SECTION_INPUT_PANE_TEXT_FIELD} type="text" />
        <button style={{ height: '86px', width: '86px' }} id={ID_SECTION_INPUT_PANE_BUTTON_RIGHT} onTouchEnd={touchEndEventOnInputPaneButton} type="button">
          <img alt="" />
        </button>
        <div id={ID_SECTION_INPUT_PANE_CHIPS} />
        <button id={ID_SECTION_INPUT_PANE_BUTTON_CHIPS} onTouchEnd={touchEndEventOnInputPaneButton} type="button">
          <img alt="" />
        </button>
      </section>
    </main>
  );
}

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------
export default Chatbot;
