/* eslint-disable no-unused-vars */
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyALqJAXAe0C-PRTj1SaA92qY7IvuzQEQfY',
  authDomain: 'sprint-digital-human-lab.firebaseapp.com',
  projectId: 'sprint-digital-human-lab',
  storageBucket: 'sprint-digital-human-lab.appspot.com',
  messagingSenderId: '71880941681',
  appId: '1:71880941681:web:4d189706b18cb6e23c7bd0',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// eslint-disable-next-line import/prefer-default-export
export const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    const { user } = userCredential;
    return user;
  })
  .catch((error) => {
    throw new Error(error);
  });

export const logOut = async () => {
  signOut(auth);
};

export default auth;
